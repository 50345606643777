import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { motion } from "framer-motion";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "./project.css";

// import required modules
import { Pagination } from "swiper/modules";
import ProjectCard from "./ProjectCard";
//images
import PythonCLI from "../brand_logo/python.jpg"
import Blog from "../brand_logo/blog.jpg"
function Projects() {
  return (
    <motion.div
      id="project_main"
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Swiper
        direction={"vertical"}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <ProjectCard
            title="Python Games"
            img_src={PythonCLI} // Placeholder image
            outcome_bio="This project focuses on basic Python programming concepts through game development. 
              Key areas include creating Command-Line Interface (CLI) applications, implementing game mechanics, and 
              using version control to collaborate on open-source projects."
            github_title="Python CLI Games"
            github_git_url="https://github.com/arshc0de/Python-CLI-Games.git"
            github_ghpage_url="https://github.com/arshc0de/Python-CLI-Games" // GitHub URL for repository
            git_status="Public"
            git_repo_url="https://github.com/arshc0de/Python-CLI-Games"
            live_project_link="https://github.com/arshc0de/Python-CLI-Games"
          />
        </SwiperSlide>
        <SwiperSlide>
          <ProjectCard
            title="Blog World"
            img_src={Blog} // Placeholder image
            outcome_bio="Blog World is a MERN stack application that allows users to manage blog posts. 
              It features user authentication, CRUD operations, and a responsive design. 
              Technologies used include MongoDB, Express.js, React.js, and Node.js."
            github_title="Blog World - MERN Stack Blog App"
            github_git_url="https://github.com/arshc0de/Blog_App.git"
            github_ghpage_url="https://github.com/arshc0de/Blog_App" // GitHub URL for repository
            git_status="Public | Developing"
            git_repo_url="https://github.com/arshc0de/Blog_App"
            live_project_link="https://github.com/arshc0de/Blog_App"
          />
        </SwiperSlide>
        <SwiperSlide>
          <ProjectCard
            title="EKart Shop - Ecommerce"
            img_src="https://via.placeholder.com/150" // Placeholder image
            outcome_bio="EKart Shop is an e-commerce platform built with the MERN stack. 
              The project is under development and includes features such as user authentication, 
              product management, shopping cart, and order management. 
              Technologies used: MongoDB, Express, React, and Node.js."
            github_title="Ekart_shop"
            github_git_url="https://github.com/arshc0de/Ekart_shop.git"
            github_ghpage_url="https://github.com/arshc0de/Ekart_shop" // GitHub URL for repository
            git_status="Public | Developing"
            git_repo_url="https://github.com/arshc0de/Ekart_shop"
            live_project_link="https://github.com/arshc0de/Ekart_shop"
          />
        </SwiperSlide>
        <SwiperSlide>
          <ProjectCard
            title="INFRA INTERIORS"
            img_src="https://via.placeholder.com/150"
            outcome_bio="INFRA INTERIORS is a basic website showcasing interior design ideas using HTML, CSS, and JavaScript. 
              Features include a responsive design, interactive elements, and a modern layout. 
              Instructions: Clone the repository and open index.html in your browser."
            github_title="Interior Design Website"
            github_git_url="https://github.com/arshc0de/Interior_Design_Website.git"
            github_ghpage_url="https://github.com/arshc0de/Interior_Design_Website" // GitHub URL for repository
            git_status="Public"
            git_repo_url="https://github.com/arshc0de/Interior_Design_Website"
            live_project_link="https://github.com/arshc0de/Interior_Design_Website"
          />
        </SwiperSlide>
        <SwiperSlide>
          <ProjectCard
            title="World Tour"
            img_src="https://via.placeholder.com/150"
            outcome_bio="World Tour is a travel agency website built with HTML, CSS, and JavaScript. 
              It includes pages for home, exploration, login, and pricing."
            github_title="Travel Website Project"
            github_git_url="https://github.com/arshc0de/Travel_Website_Project.git"
            github_ghpage_url="https://github.com/arshc0de/Travel_Website_Project" // GitHub URL for repository
            git_status="Public"
            git_repo_url="https://github.com/arshc0de/Travel_Website_Project"
            live_project_link="https://github.com/arshc0de/Travel_Website_Project"
          />
        </SwiperSlide>
        <SwiperSlide>
          <ProjectCard
            title="Nodekart.Co"
            img_src="https://via.placeholder.com/150" // Placeholder image
            outcome_bio="Nodekart.Co is an educational project developed with React.js. 
              It includes instructions for cloning, installing dependencies, and running the application. 
              Available scripts include start, test, build, and eject."
            github_title="Nodekart"
            github_git_url="https://github.com/arshc0de/Nodekart.git"
            github_ghpage_url="https://github.com/arshc0de/Nodekart" // GitHub URL for repository
            git_status="Public"
            git_repo_url="https://github.com/arshc0de/Nodekart"
            live_project_link="https://github.com/arshc0de/Nodekart"
          />
        </SwiperSlide>
        <SwiperSlide>
          <ProjectCard
            title="JavaScript Mini Projects"
            img_src="https://via.placeholder.com/150" // Placeholder image
            outcome_bio="A collection of JavaScript mini-projects including a counter webpage, to-do list, calculator, 
              digital clock, weather web app, currency converter, daily quote generator, and text-to-speech functionality."
            github_title="JavaScript Mini Projects"
            github_git_url="https://github.com/arshc0de/Javscript-Mini-Projects.git"
            github_ghpage_url="https://github.com/arshc0de/Javscript-Mini-Projects" // GitHub URL for repository
            git_status="Public"
            git_repo_url="https://github.com/arshc0de/Javscript-Mini-Projects"
            live_project_link="https://github.com/arshc0de/Javscript-Mini-Projects"
          />
        </SwiperSlide>
      </Swiper>
    </motion.div>
  );
}

export default Projects;
