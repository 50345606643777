import React from "react";
import { MdEmail, MdLocationOn, MdLocalPhone } from "react-icons/md";
import "./Resume.css";
import {
  FaHtml5,
  FaCss3,
  FaBootstrap,
  FaReact,
  FaFigma,
  FaPython,
  FaGoogle,
  FaGooglePlay,
  FaLinkedin,
  FaGithubSquare,
  FaYoutube,
} from "react-icons/fa";
import { FaMeta } from "react-icons/fa6";
import { FaMicrosoft } from "react-icons/fa6";
import { IoLogoJavascript } from "react-icons/io5";
import { RiTailwindCssFill, RiFirebaseFill } from "react-icons/ri";
import {
  SiExpress,
  SiAdobexd,
  SiLunacy,
  SiArduino,
  SiMysql,
  SiSqlite,
  SiAppwrite,
  SiGooglecloud,
  SiQt,
  SiIbm,
  SiPostman,
  SiTcs,
  SiCisco,
  SiUdacity,
  SiUipath,
  SiCoursera,
  SiDatacamp,
} from "react-icons/si";
import { BiLogoMongodb } from "react-icons/bi";
import { GrOracle } from "react-icons/gr";
import ImageGrey from "./ImageGrey";
import NYU from "../brand_logo/NYU.png";
import UM from "../brand_logo/university_of_michigan.png";
import DLAI from "../brand_logo/DLAI.png";
import OHSC from "../brand_logo/OHSC.png";
import DSEU from "../brand_logo/dseu.png";
import IPU from "../brand_logo/ipu.png";
import { motion } from "framer-motion";

function Resume() {
  return (
    <div className="resume_container">
      <header className="header">
        <img
          src="https://media.licdn.com/dms/image/v2/D5603AQEQ4rxNT5luAA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720069812051?e=2147483647&v=beta&t=GybrWEYcOysytUK8sd3rFOyJQnQqgOKW92ymmbJTj0w"
          alt="Arsh"
          className="profile_image"
        />
        <h1>Arsh</h1>
        <h2>Software Developer</h2>
        <motion.div
          className="contact_info"
          initial={{ y: "0px", opacity: 0 }}
          whileInView={{ opacity: 1, y: "12px" }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
        >
          <motion.p
            className="skill_card"
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
          >
            <MdEmail /> arsh@gmail.com
          </motion.p>
          <p className="skill_card">
            <MdLocationOn /> New Delhi, India
          </p>
          <p className="skill_card">
            <MdLocalPhone /> +91 99XXXXXXXXXX
          </p>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          whileInView={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
        >
          <a
            href="https://in.linkedin.com/in/arshmd"
            target="_black"
            style={{ color: "#0a66c2" }}
          >
            <FaLinkedin className="Main_Hub_scocial_media" />
          </a>
          <a
            href="https://github.com/arshc0der"
            target="_black"
            style={{ color: "Black" }}
          >
            <FaGithubSquare className="Main_Hub_scocial_media" />
          </a>
          <a
            href="https://www.youtube.com/@Code.ByArsh"
            target="_black"
            style={{ color: "Red" }}
          >
            <FaYoutube className="Main_Hub_scocial_media" />
          </a>
        </motion.div>
      </header>

      <motion.section
        className="about_me"
        initial={{ opacity: 0, scale: 0.5 }}
        whileInView={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.6 }}
        viewport={{ once: true }}
      >
        <p className="main_heads">About Me</p>
        <p className="res_bio">
          I'm a constant learner with a passion for Technology and Business. I'm
          always looking for new ways to expand my knowledge.
          <br />
          Currently, pursuing BCA from JIMS, Vasant Kunj (IP University).
          <br />
          I'm confident in my ability to learn new skills and to apply them to
          my work.
          <br />
          I'm also a quick learner and I'm able to adapt to new situations
          quickly.
        </p>
      </motion.section>

      <section className="education">
        <p className="main_heads">Education</p>
        <motion.div
          className="edu_inner"
          initial={{ y: "0px", opacity: 0 }}
          whileInView={{ opacity: 1, y: "12px" }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
        >
          <motion.div
            className="education_item"
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
          >
            <ImageGrey source={IPU} />
            <h4 className="college">
              Guru Gobind Singh Indraprastha University
            </h4>
            <p className="college_course">BCA, Expected 2026</p>
          </motion.div>
          <motion.div
            className="education_item"
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
          >
            <ImageGrey source={DSEU} />
            <h4 className="college">
              Delhi Skill and Entrepreneurship University
            </h4>
            <p className="college_course">
              B.Com (BPM), Completed one year (2022)
            </p>
          </motion.div>
        </motion.div>
      </section>

      <motion.section
        className="experience"
        initial={{ opacity: 0, scale: 0.5 }}
        whileInView={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.6 }}
        viewport={{ once: true }}
      >
        <p className="main_heads">Experience</p>
        <div className="ex_container">
          <p className="ex_exp">No formal experience yet</p>
        </div>
      </motion.section>

      <section className="skills">
        <p className="main_heads">Skills</p>
        <div className="skills">
          <div className="technical_skills">
            <h4 className="skill_head">Technical Skills</h4>

            <div className="skill_category">
              <p className="sub_skills">Design Software</p>
              <motion.div
                className="skill_group"
                initial={{ y: "0px", opacity: 0 }}
                whileInView={{ opacity: 1, y: "12px" }}
                transition={{ duration: 0.6 }}
                viewport={{ once: true }}
              >
                <span className="skill_card">
                  <FaFigma />
                  Figma
                </span>
                <span className="skill_card">
                  <SiAdobexd />
                  Adobe XD
                </span>
                <span className="skill_card">
                  <SiLunacy />
                  Lunacy
                </span>
              </motion.div>
            </div>

            <motion.div
              className="skill_category"
              initial={{ y: "0px", opacity: 0 }}
              whileInView={{ opacity: 1, y: "12px" }}
              transition={{ duration: 0.6 }}
              viewport={{ once: true }}
            >
              <p className="sub_skills">Web Development Skills</p>
              <div className="skill_group">
                <span className="skill_card">
                  <FaHtml5 />
                  HTML
                </span>
                <span className="skill_card">
                  <FaCss3 />
                  CSS
                </span>
                <span className="skill_card">
                  <IoLogoJavascript />
                  JavaScript
                </span>
                <span className="skill_card">
                  <FaBootstrap />
                  Bootstrap
                </span>
                <span className="skill_card">
                  <RiTailwindCssFill />
                  Tailwind CSS
                </span>
                <span className="skill_card">
                  <FaReact />
                  React
                </span>
                <span className="skill_card">
                  <SiExpress />
                  Express.js
                </span>
              </div>
            </motion.div>

            <motion.div
              className="skill_category"
              initial={{ y: "0px", opacity: 0 }}
              whileInView={{ opacity: 1, y: "12px" }}
              transition={{ duration: 0.6 }}
              viewport={{ once: true }}
            >
              <p className="sub_skills">Programming Languages</p>
              <div className="skill_group">
                <span className="skill_card">C</span>
                <span className="skill_card">C++</span>
                <span className="skill_card">
                  <FaPython />
                  Python
                </span>
                <span className="skill_card">
                  <SiArduino />
                  Arduino
                </span>
                <span className="skill_card">OOPS (With C++)</span>
              </div>
            </motion.div>

            <motion.div
              className="skill_category"
              initial={{ y: "0px", opacity: 0 }}
              whileInView={{ opacity: 1, y: "12px" }}
              transition={{ duration: 0.6 }}
              viewport={{ once: true }}
            >
              <p className="sub_skills">Database Skills</p>
              <motion.div
                className="skill_group"
                initial={{ y: "0px", opacity: 0 }}
                whileInView={{ opacity: 1, y: "12px" }}
                transition={{ duration: 0.6 }}
                viewport={{ once: true }}
              >
                <span className="skill_card">
                  <BiLogoMongodb />
                  MongoDB (Intermediate)
                </span>
                <span className="skill_card">
                  <SiMysql />
                  MySQL
                </span>
                <span className="skill_card">
                  <SiSqlite />
                  SQLite
                </span>
                <span className="skill_card">
                  <GrOracle />
                  Oracle
                </span>
                <span className="skill_card">
                  <RiFirebaseFill />
                  Firebase
                </span>
                <span className="skill_card">
                  <SiAppwrite />
                  Appwrite
                </span>
                <span className="skill_card">
                  <SiGooglecloud />
                  Google Cloud
                </span>
              </motion.div>
            </motion.div>

            <motion.div
              className="skill_category"
              initial={{ y: "0px", opacity: 0 }}
              whileInView={{ opacity: 1, y: "12px" }}
              transition={{ duration: 0.6 }}
              viewport={{ once: true }}
            >
              <p className="sub_skills">Other Skills</p>
              <div className="skill_group">
                <span className="skill_card">DSA (C, Learning with C++)</span>
                <span className="skill_card">
                  <SiQt />
                  QT C++
                </span>
              </div>
            </motion.div>
          </div>

          <div className="soft_skills">
            <h4 className="skill_head">Soft Skills</h4>
            <motion.div
              className="skill_group"
              initial={{ y: "0px", opacity: 0 }}
              whileInView={{ opacity: 1, y: "12px" }}
              transition={{ duration: 0.6 }}
              viewport={{ once: true }}
            >
              <span className="skill_card">Problem Solving</span>
              <span className="skill_card">Communication</span>
              <span className="skill_card">Team Collaboration</span>
              <span className="skill_card">Adaptability</span>
              <span className="skill_card">Time Management</span>
              <span className="skill_card">Creativity</span>
              <span className="skill_card">Attention to Detail</span>
              <span className="skill_card">Critical Thinking</span>
            </motion.div>
          </div>
        </div>
      </section>

      <section className="certifications">
        <p className="main_heads">Certifications</p>
        <motion.div
          className="certi_view"
          initial={{ y: "0px", opacity: 0 }}
          whileInView={{ opacity: 1, y: "12px" }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
        >
          <motion.div
            className="certification_item"
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
          >
            <h4>AI Text Summarizer App</h4>
            <div className="main_cert_card">
              <SiPostman className="company_logo" />
              <div>
                <p>Postman - Issued Apr 2024</p>
                <p>Credential ID: uxo7chxgfrue</p>
              </div>
            </div>
          </motion.div>
          <motion.div
            className="certification_item"
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
          >
            <h4>Information Technology Fundamentals</h4>
            <div className="main_cert_card">
              <SiIbm className="company_logo" />
              <div>
                <p>IBM - Issued Mar 2024</p>
              </div>
            </div>
          </motion.div>
          <motion.div
            className="certification_item"
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
          >
            <h4>Working in a Digital World: Professional Skills</h4>
            <div className="main_cert_card">
              <SiIbm className="company_logo" />
              <div>
                <p>IBM - Issued Mar 2024</p>
              </div>
            </div>
          </motion.div>
          <motion.div
            className="certification_item"
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
          >
            <h4>Data Analysis with Python</h4>
            <div className="main_cert_card">
              <SiIbm className="company_logo" />
              <div>
                <p>IBM - Issued Dec 2023</p>
                <p>Credential ID: 3LY5BLTFRMP9</p>
              </div>
            </div>
          </motion.div>
          <motion.div
            className="certification_item"
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
          >
            <h4>
              Tata Consultancy Services - Cybersecurity Analyst Job Simulation
            </h4>
            <div className="main_cert_card">
              <SiTcs className="company_logo" />
              <div>
                <p>Forage - Issued Jan 2024</p>
                <p>Credential ID: yfiGMXKdnPdf8Fp52</p>
              </div>
            </div>
          </motion.div>
          <motion.div
            className="certification_item"
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
          >
            <h4>Crash Course on Python</h4>
            <div className="main_cert_card">
              <FaGoogle className="company_logo" />
              <div>
                <p>Google - Issued Dec 2023</p>
                <p>Credential ID: TUPPR3RPY8EH</p>
              </div>
            </div>
          </motion.div>

          <motion.div
            className="certification_item"
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
          >
            <h4>Introduction to Cyber Attacks</h4>
            <div className="main_cert_card">
              <ImageGrey source={NYU} />
              <div>
                <p>New York University - Issued Dec 2023</p>
                <p>Credential ID: EL4QNWK2FJLH</p>
              </div>
            </div>
          </motion.div>
          <motion.div
            className="certification_item"
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
          >
            <h4>Introduction to Cybersecurity</h4>
            <div className="main_cert_card">
              <SiCisco className="company_logo" />
              <div>
                <p>Cisco - Issued Dec 2023</p>
              </div>
            </div>
          </motion.div>
          <motion.div
            className="certification_item"
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
          >
            <h4>Blockchain and Cryptocurrency Explained</h4>
            <div className="main_cert_card">
              <ImageGrey source={UM} />
              <div>
                <p>University of Michigan - Issued Nov 2023</p>
                <p>Credential ID: 965242YSE8AZ</p>
              </div>
            </div>
          </motion.div>
          <motion.div
            className="certification_item"
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
          >
            <h4>Create Image Captioning Models with Google Cloud</h4>
            <div className="main_cert_card">
              <SiUdacity className="company_logo" />
              <div>
                <p>Udacity - Issued Nov 2023</p>
              </div>
            </div>
          </motion.div>
          <motion.div
            className="certification_item"
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
          >
            <h4>Introduction to Front-End Development</h4>
            <div className="main_cert_card">
              <FaMeta className="company_logo" />
              <div>
                <p>Meta - Issued Nov 2023</p>
                <p>Credential ID: QUTH54BJ4C7G</p>
              </div>
            </div>
          </motion.div>
          <motion.div
            className="certification_item"
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
          >
            <h4>Programming with JavaScript</h4>
            <div className="main_cert_card">
              <FaMeta className="company_logo" />
              <div>
                <p>Meta - Issued Nov 2023</p>
                <p>Credential ID: DGZT7CFNWZ28</p>
              </div>
            </div>
          </motion.div>
          <motion.div
            className="certification_item"
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
          >
            <h4>A Day in the Life of an RPA Developer</h4>
            <div className="main_cert_card">
              <SiUipath className="company_logo" />
              <div>
                <p>UiPath - Issued Oct 2023</p>
              </div>
            </div>
          </motion.div>
          <motion.div
            className="certification_item"
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
          >
            <h4>AI For Everyone</h4>
            <div className="main_cert_card">
              <ImageGrey source={DLAI} />
              <div>
                <p>DeepLearning.AI - Issued Oct 2023</p>
                <p>Credential ID: B72PVBWRXQDK</p>
              </div>
            </div>
          </motion.div>
          <motion.div
            className="certification_item"
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
          >
            <h4>Build Responsive CSS3 Flexbox Holy Grail Layout</h4>
            <div className="main_cert_card">
              <SiCoursera className="company_logo" />
              <div>
                <p>Coursera - Issued Oct 2023</p>
                <p>Credential ID: U45WVRMPY82J</p>
              </div>
            </div>
          </motion.div>
          <motion.div
            className="certification_item"
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
          >
            <h4>Career Essentials in Cybersecurity</h4>
            <div className="main_cert_card">
              <FaMicrosoft className="company_logo" />
              <div>
                <p>Microsoft - Issued Oct 2023</p>
                <p className="cred">
                  Credential ID: 73f02205533971af6f36aeccff11f619
                  61282465e68377bdd1ca54f630284ebe
                </p>
              </div>
            </div>
          </motion.div>
          <motion.div
            className="certification_item"
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
          >
            <h4>
              Career Essentials in Generative AI by Microsoft and LinkedIn
            </h4>
            <div className="main_cert_card">
              <FaMicrosoft className="company_logo" />
              <div>
                <p>Microsoft - Issued Aug 2023</p>
                <p className="cred">
                  Credential ID: b0bb9aa0e932ff4012ef9fcdce6dbc34
                  ee66c29396148ea5440a98064bfba73b
                </p>
              </div>
            </div>
          </motion.div>
          <motion.div
            className="certification_item"
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
          >
            <h4>Diploma in Cyber Security</h4>
            <div className="main_cert_card">
              <ImageGrey source={OHSC} />
              <div>
                <p>Oxford Home Study Center OHSC - Issued Aug 2023</p>
              </div>
            </div>
          </motion.div>
          <motion.div
            className="certification_item"
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
          >
            <h4>Google Play Academy - Store Listing Certificate</h4>
            <div className="main_cert_card">
              <FaGooglePlay className="company_logo" />
              <div>
                <p>Google Play - Issued Jan 2024, Expires Jan 2027</p>
                <p>Credential ID: 92077477</p>
              </div>
            </div>
          </motion.div>
          <motion.div
            className="certification_item"
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
          >
            <h4>Data Analyst Associate</h4>
            <div className="main_cert_card">
              <SiDatacamp className="company_logo" />
              <div>
                <p>DataCamp - Issued Apr 2024, Expires Apr 2026</p>
                <p>Credential ID: DAA0018451028893</p>
              </div>
            </div>
          </motion.div>
          <motion.div
            className="certification_item"
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
          >
            <h4>Data Engineer Associate</h4>
            <div className="main_cert_card">
              <SiDatacamp className="company_logo" />
              <div>
                <p>DataCamp - Issued Apr 2024, Expires Apr 2026</p>
                <p>Credential ID: DEA0018606176829</p>
              </div>
            </div>
          </motion.div>
          <motion.div
            className="certification_item"
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
          >
            <h4>SQL Associate</h4>
            <div className="main_cert_card">
              <SiDatacamp className="company_logo" />
              <div>
                <p>DataCamp - Issued Apr 2024, Expires Apr 2026</p>
                <p>Credential ID: SQA0017121169937</p>
              </div>
            </div>
          </motion.div>
        </motion.div>
      </section>

      {/* New Publications Section */}
      <section className="publications">
        <p className="main_heads">Publications</p>
        <div className="publication_sub">
          <motion.div
            className="publication_item publish_item"
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
          >
            <h4>Sustainable Technology Solutions for Modern Businesses</h4>
            <p>Published on LinkedIn - July 2024</p>
            <p>
              <a
                href="https://www.linkedin.com/pulse/sustainable-technology-solutions-modern-businesses-md-arsh-mn2sc"
                target="_blank"
                rel="noopener noreferrer"
              >
                Read more
              </a>
            </p>
          </motion.div>
          <motion.div
            className="publication_item publish_item"
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
          >
            <h4>Understanding Blockchain Technology</h4>
            <p>Published on LinkedIn - Oct 2023</p>
            <p>
              <a
                href="https://www.linkedin.com/pulse/bpm-cornerstone-modern-business-success-md-arsh"
                target="_blank"
                rel="noopener noreferrer"
              >
                Read more
              </a>
            </p>
          </motion.div>
        </div>
      </section>
    </div>
  );
}

export default Resume;
